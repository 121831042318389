import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import {PageContentContainer} from "../styled/styled";
import {CenteredColumnFlexBox, CenteredRow, FlexCol} from "../simulation/create-simulation/styled";
import {useEffect, useMemo, useRef, useState} from "react";
import {Alert, Card, CircularProgress, FormControl, InputLabel, MenuItem} from "@mui/material";
import {createSmartWallet, getSmartWalletById, getSupportedChains, updateSmartWallet} from "../../api/hasura";
import useSWR from "swr";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAppState} from "../../state/AppStateProvider";
import {SafeFactory, SafeAccountConfig} from '@safe-global/protocol-kit';
import Safe from '@safe-global/protocol-kit';
import Web3 from "web3";
import {LoadingButton} from "@mui/lab";
import WalletStatus from "./WalletStatus";
import IconButton from "@mui/material/IconButton";
import {Refresh} from "@mui/icons-material";

import {encodeBytes32String} from 'defi-kit';
import {applyMembers, setUpRolesMod} from 'zodiac-roles-sdk';
import {ethers} from "ethers";

import {TransactionResponse} from '@ethersproject/providers';
import {fetchRole} from "zodiac-roles-deployments";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {delegateRole, getApplyRoleTRX, createZodiacPermissioTxnWithPermission} from "./safeFunctions";

import {Flex, Text, Button, Select, TextField, Callout, Spinner} from "@radix-ui/themes";
import {ArrowLeftIcon, ArrowRightIcon, CheckIcon, OpenInNewWindowIcon, ReloadIcon} from '@radix-ui/react-icons';
import { formatPubKey } from '../../utils/commonUtils';
import { ChainBadge } from './ChainBadge';


export const ROLES_MOD_ADDRESS = '0x9646fDAD06d3e24444381f44362a3B0eB343D337'; // Zodiac Roles Modules Factory address
export const ALMANAK_AGENT_ROLE_NAME = "AlmanakAgentRole";
export const ALMANAK_AGENT_ROLE_KEY = encodeBytes32String(ALMANAK_AGENT_ROLE_NAME);

const steps = ['Create Wallet', 'Confirm Transaction', "Add Modules", "Delegate Access"];

const descriptions = [
    null,
    'Sign a transaction from your wallet to confirm the creation of your Safe Wallet.',
    'Deploy a security module to set up roles, allowing you to assign permissions and control access to your Safe Wallet.',
    'Assign the Almanak Agent role to the Safe wallet, so the Almanak Agent can interact with the strategy using your wallet and your allowed permissions.',
]

export default function WalletCreationFlow() {

    const navigate = useNavigate();
    const {setSnackBar, provider, user} = useAppState();
    const {id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const {connectWallet} = usePrivy();
    const {wallets} = useWallets();
    console.log("WalletCreationFlow wallets", wallets);
    const wallet = wallets[0];

    console.log("provider", provider);

    const web3 = new Web3(provider as any)

    const {
        data: supportedChains,
        isLoading: chainsLoading,
    } = useSWR("supported-chains", getSupportedChains)


    const {
        data: walletDetails,
        isLoading: isWalletLoading,
        isValidating: isWalletValidating,
        mutate: mutateWalletDetails
    } = useSWR(id ? `wallets/${id}` : null, () => getSmartWalletById(id));


    const {tx_hash} = walletDetails ?? {};


    // const [activeStep, setActiveStep] = React.useState(tx_hash ? 2 : id ? 1 : 0);

    const [isNextLoading, setIsNextLoading] = useState(false);

    const [selectedChain, setSelectedChain] = useState("42161"); // arbitrum chain id

    const [walletName, setWalletName] = useState('');
    const [isFetchingWalletModuleAndRoles, setIsFetchingWalletModuleAndRoles] = useState(true);

    const [isRolesModEnabled, setIsRolesModEnabled] = React.useState(false);
    const [isRoleSetup, setIsRoleSetup] = React.useState(false);


    const prevActiveStepRef = useRef<number>(0);  // Allow number or null


    const activeStep = useMemo(() => {
        let result;
        if (isWalletLoading || isWalletValidating) {
            result = prevActiveStepRef.current; // Return previous value if loading or validating
        } else if (isRolesModEnabled && isRoleSetup) {
            result = 4;
        } else if (isRolesModEnabled) {
            result = 3;
        } else if (walletDetails?.tx_hash) {
            result = 2;
        } else if (id) {
            result = 1;
        } else {
            result = 0;
        }

        // Store the current result as the previous value for the next render
        prevActiveStepRef.current = result;
        return result ?? 0;
    }, [id, walletDetails?.tx_hash, isWalletLoading, isWalletValidating, isRolesModEnabled, isRoleSetup]);


    useEffect(() => {


        if (searchParams.get("autoSign") === "true" && !walletDetails?.tx_hash) {
            submitTransaction(walletDetails?.id, walletDetails?.external_own_accounts?.[0]?.address);
        }

        // if (searchParams.get("autoSign") === "true" && walletDetails?.tx_hash && !isRolesModEnabled) {
        //     enableAccessForAlmanakAgent();
        // } else if (searchParams.get("autoSign") === "true" && walletDetails?.tx_hash && isRolesModEnabled && !isRoleSetup) {
        //     delegateRole();
        // }


    }, [activeStep, walletDetails]);

    useEffect(() => {
        if (!provider) {
            connectWallet();
        }
    }, []);


    const isNextDisabled = useMemo(() => {
        return activeStep === 0 && walletName.length < 3;
    }, [activeStep, walletName]);


    const getNextButtonLabel = () => {
        switch (activeStep) {
            case 0:
                return 'Create Wallet';
            case 1:
                return 'Sign Transaction';
            case 2:
                return 'Enable Module';
            case 3:
                return 'Delegate Role';
            case 4:
                return "Open Wallet"
            default:
                return 'Next';
        }
    };

    const getDeployRolesModuleTrx = async () => {


        const saltBytes = ethers.utils.randomBytes(32);
        const saltHex = ethers.utils.hexlify(saltBytes);


        const saltNonce = saltHex as `0x${string}`;


        const rolesModTrx = setUpRolesMod({
            avatar: walletDetails?.address,
            saltNonce: saltNonce
        });

        return rolesModTrx

    };

    // const delegateRole = async () => {
    //     if (!provider) {
    //         console.error('provider not initialized!');
    //         return
    //
    //     }
    //
    //     const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});
    //
    //     const almanakEOAAddress = walletDetails?.external_own_accounts[0].address;
    //
    //     const modules = await protocolKit.getModules();
    //
    //     const delegateRolTrx = await getDelegateRoleTRX(modules[0], walletDetails.chain_id, ALMANAK_AGENT_ROLE_NAME, [almanakEOAAddress]);
    //
    //     console.log("delegateRolTrx", delegateRolTrx);
    //
    //     const safeTransaction = await protocolKit.createTransaction({transactions: delegateRolTrx})
    //     const txResponse = await protocolKit.executeTransaction(safeTransaction)
    //
    //     return txResponse;
    //
    //
    // }

    const enableAccessForAlmanakAgent = async () => {

        if (!provider) {
            console.error('provider not initialized!');
            return

        }

        setIsNextLoading(true);

        const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});

        const rolesModTrx = await getDeployRolesModuleTrx();

        console.log("enableAccessForAlmanakAgent rolesModTrx", rolesModTrx);
        const expectedZodiacModuleAddress = rolesModTrx[1].to;

        //may need to just create one permission if not roles are not created
        console.log("expectedZodiacModuleAddress", expectedZodiacModuleAddress);
        const applyRoleTrx = await getApplyRoleTRX(expectedZodiacModuleAddress)

        const transactions = [...rolesModTrx, ...applyRoleTrx]

        const safeTransaction = await protocolKit.createTransaction({transactions: transactions})
        const txResponse = await protocolKit.executeTransaction(safeTransaction)

        if (txResponse.transactionResponse) {
            const transactionResponse = txResponse.transactionResponse as TransactionResponse;
            await transactionResponse.wait(); // Call wait() on transactionResponse
            console.log('Zodiac Roles modifier module is deployed and enabled');
            console.log('Contract address: ', expectedZodiacModuleAddress);
        } else {
            console.error('transactionResponse is undefined.');
        }
    };


    const onChainChange = (chainIdString: string) => {
        const chainId = Number(chainIdString);
        setSelectedChain(chainIdString);
        wallet?.switchChain(chainId);
    };

    const fetchWalletModuleAndRoles = async () => {

        console.log("fetchWalletModuleAndRoles start");
        setIsFetchingWalletModuleAndRoles(true);
        if (!provider) {
            console.log("fetchWalletModuleAndRoles NO PROVIDER, connecting Wallet");
            connectWallet();
            setIsFetchingWalletModuleAndRoles(false);
            return;
        }


        try {
            const safe = await Safe.init({provider: provider, safeAddress: walletDetails?.address});

            // //this is not consistent, it does not always return true even though module is enabled.
            // let isRolesModEnabled = await safe.isModuleEnabled(ROLES_MOD_ADDRESS);

            const modules = await safe.getModules();
            const isModuleAdded = modules?.length > 0;

            console.log("fetchWalletModuleAndRoles modules", modules);
            setIsRolesModEnabled(isModuleAdded);

            if (isModuleAdded) {
                console.log("modules when fetching role", modules);

                const role = await fetchRole({
                    address: modules[0] as `0x${string}`,
                    roleKey: ALMANAK_AGENT_ROLE_KEY,
                    chainId: walletDetails.chain_id
                });

                const isRoleAdded = role !== null && role?.members[0] === walletDetails.external_own_accounts[0].address;
                setIsRoleSetup(isRoleAdded);
            }
        } catch (e: any) {
            console.error("Error fetching wallet module and roles", e);
            setSnackBar({open: true, message: e.message, severity: "error"});
        } finally {
            setIsFetchingWalletModuleAndRoles(false);
        }


    }

    // useEffect(() => {
    //     // Set up an interval to call fetchRolesModStatus every 3 seconds
    //     let intervalId: any;
    //     // if (walletDetails?.address && (!isRolesModEnabled || !isRoleSetup)) {
    //     //     intervalId = setInterval(fetchWalletModuleAndRoles, 3000);
    //     // } else {
    //     //     clearInterval(intervalId);
    //     // }
    //
    //
    //     if (walletDetails?.chain_id) {
    //         wallet?.switchChain(walletDetails?.chain_id);
    //     }
    //
    //     // Clean up the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    //
    // }, [walletDetails?.address, isRolesModEnabled, isRoleSetup]);


    useEffect(() => {

        const init = async () => {
            if (walletDetails?.id) {
                await fetchWalletModuleAndRoles();
            } else {
                setIsFetchingWalletModuleAndRoles(false);
            }
        }

        init();
    }, [walletDetails?.id]);


    // useEffect(() => {
    //
    //     fetchRolesModStatus();
    //
    // }, [provider, walletDetails]);

    const createSafeTransaction = async (eoaWalletAddress: string): Promise<{
        txHash?: string,
        address?: string,
        success: boolean
    }> => {


        if (!provider) {
            console.error('createSafeTransaction provider not initialized yet');
            return {success: false};
        }
        const address = user?.publicKey;
        const safeFactory = await SafeFactory.init({provider: provider});

        if (!address || !eoaWalletAddress) {
            console.error("createSafeTransaction User address or EOA address not found not found");
            console.log('createSafeTransaction User address: ', address);
            console.log('createSafeTransaction EOA WalletAddress: ', eoaWalletAddress);
            return {success: false};
        }
        const safeAccountConfig: SafeAccountConfig = {
            owners: [address],
            threshold: 1,
        };

        // THIS IS IF EAO WALLET IS ALSO OWNER
        // const safeAccountConfig: SafeAccountConfig = {
        //     owners: [address, eoaWalletAddress],
        //     threshold: 2,
        // };
        console.log("safeAccountConfig", safeAccountConfig);
        const nonce = await web3.eth.getTransactionCount(address);
        console.log('Nonce: ', nonce.toString());

        const saltNonce = nonce.toString();
        const predictedSafeAddress = await safeFactory.predictSafeAddress(safeAccountConfig, saltNonce);
        console.log('Predicted Safe Address: ', predictedSafeAddress);
        try {
            let txHash;
            const protocolKitOwner = await safeFactory.deploySafe({
                safeAccountConfig,
                saltNonce: saltNonce,
                callback: (callbackTxHash: string) => {
                    txHash = callbackTxHash;
                    console.log('callbackTxHash from deploy safe: ', callbackTxHash);
                },
            });
            const safeAddress = await protocolKitOwner.getAddress();

            if (!txHash) {
                console.error('Transaction hash not found');
                return {success: false};
            }
            return {address: safeAddress, txHash, success: true};


        } catch (error) {
            console.error('Error: ', error);
            return {success: false};
        }
    }


    const submitTransaction = async (walletId: string, externalWalletAddress: string) => {
        setIsNextLoading(true);

        try {

            console.log("externalWalletAddress", externalWalletAddress);
            // const externalWalletAddress = walletDetails?.external_own_accounts?.[0]?.address;
            const transaction = await createSafeTransaction(externalWalletAddress);

            console.log("submitTransaction transaction", transaction);

            const {txHash, address} = transaction;

            console.log("txHash", txHash);
            if (txHash && address && transaction.success) {
                const updatedTrx = await updateSmartWallet(walletId, txHash, address);

                console.log("updatedTrx", updatedTrx);
                setSnackBar({
                    open: true,
                    message: "Wallet created successfully.",
                    severity: "success"
                });
            } else {
                console.error("Error creating wallet, missing details", txHash, address, transaction.success);
                setSnackBar({open: true, message: "Wallet not created. Missing details", severity: "error"});
            }
        } catch (e: any) {
            console.error("Error submitting transaction", e);
            setSnackBar({open: true, message: e.message, severity: "error"});
        } finally {
            await mutateWalletDetails();
            setIsNextLoading(false);
        }

    }


    const handleNext = async () => {

            console.log("handleNext wallet.isConnected", wallet?.isConnected);

            const chainOfTheWallet = walletDetails?.chain_id ?? Number(selectedChain);
            wallet?.switchChain(chainOfTheWallet);

            if (activeStep === 0) {

                setIsNextLoading(true);
                try {

                    const wallet = await createSmartWallet(
                        selectedChain,
                        walletName,
                        'SAFE' // CURRENTLY ONLY AVAILABLE OPTION
                    );


                    console.log("createSmartWallet wallet", wallet);


                    if (wallet.valid) {

                        const eoaWalletAddress = wallet?.smartWalletDetails?.eoa_address;
                        const walletId = wallet?.smartWalletDetails?.id;
                        console.log("eoaWalletAddress", eoaWalletAddress);
                        console.log("walletId", walletId);
                        await submitTransaction(walletId, eoaWalletAddress);

                        // setSnackBar({
                        //     open: true,
                        //     message: "Wallet created successfully.",
                        //     severity: "success"
                        // });
                        // setActiveStep((prevActiveStep) => prevActiveStep + 2);
                        navigate(`/wallets/create/${wallet.smartWalletDetails.id}`);
                    } else {
                        console.error("Error creating wallet", wallet);
                        setSnackBar({open: true, message: `Wallet not created. ${wallet.message}`, severity: "error"});
                    }

                } catch (e: any) {
                    console.error("Error submitting transaction", e);
                    setSnackBar({open: true, message: e.message, severity: "error"});
                } finally {
                    await mutateWalletDetails();
                }
            } else if (activeStep === 1) {
                try {
                    setIsNextLoading(true);
                    await submitTransaction(walletDetails.id, walletDetails.external_own_accounts[0].address);
                } catch (e: any) {
                    console.error("Error submitting transaction", e);
                    setSnackBar({open: true, message: e.message, severity: "error"});
                } finally {
                    await mutateWalletDetails();
                    setIsNextLoading(false);
                }
            } else if (activeStep === 2) {
                try {
                    await enableAccessForAlmanakAgent()
                    setIsRolesModEnabled(true)
                    setSnackBar({open: true, message: "Module Enabled", severity: "success"});
                } catch (e: any) {
                    console.error("Error enabling roles module", e);
                    setSnackBar({open: true, message: e.message, severity: "error"});
                } finally {
                    await mutateWalletDetails();
                    setIsNextLoading(false);
                }

            } else if (activeStep === 3) {
                try {
                    setIsNextLoading(true);
                    const trx = await delegateRole(provider, walletDetails.external_own_accounts[0].address, walletDetails.address, walletDetails.chain_id);
                    console.log("delegate role trx", trx);
                    setSnackBar({open: true, message: "Wallet Setup Complete", severity: "success"});
                    setIsRoleSetup(true);
                    navigate(`/wallets/${id}`)

                } catch (e: any) {
                    console.error("Error delegating role", e);
                    setSnackBar({open: true, message: e.message, severity: "error"});
                } finally {
                    await mutateWalletDetails();
                    setIsNextLoading(false);
                }

            } else if (activeStep === 4) {
                navigate(`/wallets/${id}`)
            }
        }
    ;


    const walletCreationForm = useMemo(() => {
        return (
            <Box sx={{width: "100%"}} component="form" noValidate autoComplete="off">
                {/*<TextField*/}
                {/*    onChange={(e) => setWalletName(e.target.value)}*/}
                {/*    label="Wallet Name"*/}
                {/*    variant="outlined"*/}
                {/*    fullWidth*/}
                {/*    margin="normal"*/}
                {/*    error={walletName.length < 3}*/}
                {/*/>*/}
                <Text size="2" weight="bold">Wallet Name</Text>

                <TextField.Root placeholder="Enter Wallet name" value={walletName}
                                onChange={(e) => setWalletName(e.target.value)}>
                </TextField.Root>

                {/*Error message if no wallet name*/}
                {walletName.length < 3 && (
                    <Box sx={{mb: 2, color: "#d32f2f", fontSize: "0.8rem", textAlign: "left"}}>
                        Wallet name must be at least 3 characters long
                    </Box>
                )}

                <FormControl fullWidth margin="normal">
                    <Text size="2" weight="bold">Wallet Type</Text>

                    <Select.Root value={"SAFE"} onValueChange={() => null}>
                        <Select.Trigger placeholder="Select wallet type"/>
                        <Select.Content>
                            <Select.Item value="SAFE">SAFE</Select.Item>
                            <Select.Item disabled value="EULITH">EULITH</Select.Item>
                        </Select.Content>
                    </Select.Root>

                    {/*<Select*/}
                    {/*    labelId="wallet-type-label"*/}
                    {/*    value="SAFE"*/}
                    {/*    label="Type"*/}
                    {/*    size="small"*/}
                    {/*>*/}
                    {/*    <MenuItem value="SAFE">Safe</MenuItem>*/}
                    {/*    <MenuItem disabled value="EULITH">Eulith</MenuItem>*/}
                    {/*</Select>*/}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <Text size="2" weight="bold">Chain</Text>
                    <Select.Root value={selectedChain} onValueChange={onChainChange}>
                        <Select.Trigger placeholder="Select Chain"/>
                        <Select.Content>

                            {chainsLoading ? <Select.Item disabled value={"loading"}>Loading...</Select.Item> :
                                supportedChains?.map(chain => (
                                    <Select.Item key={chain.chain_id} value={chain.chain_id}>
                                        {chain.name}
                                    </Select.Item>
                                ))}
                            {/*{supportedChains?.map(chain => (*/}
                            {/*<Select.Item value="SAFE">SAFE</Select.Item>*/}
                            {/*<Select.Item disabled value="EULITH">EULITH</Select.Item>*/}
                        </Select.Content>
                    </Select.Root>

                    {/*<Select*/}
                    {/*    labelId="chain-label"*/}
                    {/*    value={selectedChain}*/}
                    {/*    onChange={onChainChange}*/}
                    {/*    label="Chain"*/}
                    {/*    size="small"*/}
                    {/*>*/}
                    {/*    {supportedChains?.map(chain => (*/}
                    {/*        <MenuItem value={chain.chain_id} key={chain.chain_id}>*/}
                    {/*            {chain.name}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}
                </FormControl>
                {/*<TextField*/}
                {/*    label="Owner"*/}
                {/*    variant="outlined"*/}
                {/*    fullWidth*/}
                {/*    margin="normal"*/}
                {/*/>*/}
            </Box>
        );
    }, [selectedChain, supportedChains, walletName]);

    const walletConfirmationView = useMemo(() => {
        // Find the chain name based on the chain_id
        const chainName = supportedChains?.find(chain => chain.chain_id === walletDetails?.chain_id)?.name || "Unknown Chain";

        // Predicted wallet address
        const predictedAddress = walletDetails?.external_own_accounts?.[0]?.address || "Address not available";

        return (
            <Box className={"w-100"}>
                <h2>Confirm Wallet Creation</h2>
                <div className={"gap-1 fc"}>
                    <div><strong>Name:</strong> {walletDetails?.name}</div>
                    <div><strong>Chain:</strong> {chainName} (ID: {walletDetails?.chain_id})</div>
                    <div><strong>Transaction hash:</strong> {walletDetails?.tx_hash ?? "N/A"}</div>
                    <div><strong>Type:</strong> {walletDetails?.type}</div>
                    <div><strong>Predicted Wallet Address:</strong> {predictedAddress}</div>
                    <div><strong>Is Layer 2:</strong> {walletDetails?.is_layer2 ? "Yes" : "No"}</div>
                    <div><strong>Is Testnet:</strong> {walletDetails?.is_testnet ? "Yes" : "No"}</div>
                </div>
            </Box>
        );
    }, [walletDetails]);


    // const walletDepositAssetsView = useMemo(() => {
    //     return (
    //         <Box className={"w-100"}>
    //             <h2>Deposit Assets</h2>
    //             <div className={"gap-1 fc"}>
    //                 <div>Deposit Assets</div>
    //             </div>
    //
    //             {/* Input field and button */}
    //             <TextField
    //                 label="Amount"
    //                 variant="outlined"
    //                 fullWidth
    //                 margin="normal"
    //                 type="number"
    //                 inputProps={{min: "0", step: "0.01"}}
    //             />
    //             <Button variant="contained" color="primary">
    //                 Deposit
    //             </Button>
    //         </Box>
    //     );
    // }, []);


    const submittedWalletView = useMemo(() => {
        return (
            <Flex direction="column" gap="4" width={"100%"}>
                <Callout.Root color="green">
                    <Callout.Icon>
                        <CheckIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        Wallet Created Successfully
                    </Callout.Text>
                </Callout.Root>

                <Card>
                    <Flex direction="column" gap="4" p="4">
                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Wallet Name</Text>
                            <Text size="2">{walletDetails?.name || 'Unnamed Wallet'}</Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Status</Text>
                            <WalletStatus wallet={walletDetails} />
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Chain</Text>
                            <ChainBadge chainId={walletDetails?.chain_id} />
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Address</Text>
                            <Text size="2" style={{ fontFamily: 'monospace' }}>
                                {formatPubKey(walletDetails?.address)}
                            </Text>
                        </Flex>
                    </Flex>
                </Card>
            </Flex>
        );
    }, [walletDetails]);


    const rolesModuleView = useMemo(() => {
        return (
            <Box sx={{my: 2, gap: 2}}>

                {/*{!isRolesModEnabled && <Alert severity={"info"}>*/}
                {/*    Please enable the Almanak Agent access to this wallet*/}
                {/*</Alert>}*/}

                {/*<Box sx={{my: 2}}>*/}
                {/*    {!isRoleSetup && isRolesModEnabled &&*/}
                {/*        <Button variant={"contained"} onClick={delegateRole}>Delegate Access</Button>}*/}
                {/*</Box>*/}


                {/*{isRolesModEnabled && <Chip color={"success"} label={"Wallet Setup Finished"}/>}*/}
                {/*{!isRoleSetup && isRolesModEnabled && <Chip color={"success"} label={"Roles Module is Enabled"}/>}*/}

            </Box>
        );
    }, [isRolesModEnabled]);

    const openDocs = (url: string) => {
        window.open(url, "_blank");
    }
    const getStepDescription = (step: number) => {

        if (isWalletLoading || isFetchingWalletModuleAndRoles) {
            return null;
        }
        const stepInfo = {
            0: {
                title: "Create Your Wallet",
                description: "Begin by creating your wallet on our platform. This step registers your wallet in our system, and submits it on-chain.",
                docsLink: "https://docs.almanak.co/docs/getting-started/"
            },
            1: {
                title: "Sign Transaction",
                description: "Sign a transaction to create your Safe wallet on-chain. This establishes your wallet on the blockchain, allowing for secure interactions.",
                docsLink: "https://docs.almanak.co/docs/getting-started/"
            },
            2: {
                title: "Add Role Module",
                description: "Add a role module to your wallet. This module lets you define and control permissions, ensuring secure access and management.",
                docsLink: "https://docs.almanak.co/docs/getting-started/"
            },
            3: {
                title: "Delegate Access",
                description: "Delegate access to our system wallet. This enables secure actions on your behalf, such as trades or transactions, when used within strategies by an agent.",
                docsLink: "https://docs.almanak.co/docs/getting-started/"
            }
        };

        if (!stepInfo[step as keyof typeof stepInfo]) {
            return null;
        }

        return (
            <Flex direction="column" gap="2" mb="8">
                <Text size="5" weight="bold">
                    {stepInfo[step as keyof typeof stepInfo].title}
                </Text>
                <Text color="gray">
                    {stepInfo[step as keyof typeof stepInfo].description}
                </Text>
                <div><Button variant={"outline"} onClick={() => openDocs(stepInfo[step as keyof typeof stepInfo].docsLink)}>Learn more <OpenInNewWindowIcon /></Button></div>
            </Flex>
        );
    };

    const getStepContent = (step: number) => {

        switch (step) {
            case 0:
                return walletCreationForm;
            case 1:
                return null;
            case 2:
            case 3:
                return rolesModuleView;
            case 4:
                return submittedWalletView;
            default:
                return <div>Unknown Step</div>;
        }
    };

    return (
        <PageContentContainer sx={{my: 4, width: '100%'}}>
            <Flex align={"center"} gap={"2"}>
                <Button variant={"outline"} onClick={() => navigate("/wallets")}>
                    <ArrowLeftIcon/>Back</Button>
                <h2> {activeStep === 0 ? "Create New Wallet" : `Finish Wallet "${walletDetails?.name}" Setup`}</h2>
            </Flex>

            <Flex direction={"row"} gap="3" width="100%" mb="6">
                {steps.map((label, index) => {
                    const isCompleted = index < activeStep;
                    const isCurrent = index === activeStep;
                    const isFuture = index > activeStep;
                    //
                    if (index === 1 && activeStep !== 1) {
                        return null;
                    }
                    return (
                        <>
                            <Button
                                key={label}
                                size="4"
                                variant={isCompleted ? "soft" : "soft"}
                                color={isCurrent ? "indigo" : isCompleted ? "green" : "gray"}
                                disabled={isFuture}
                                style={{flex: 1, height: 54}}
                            >
                                {`${label}`}
                                {isCompleted && <CheckIcon width={24} height={24}/>}
                            </Button>

                            <Flex align={"center"}>
                                {index !== steps.length - 1 && <ArrowRightIcon/>}
                            </Flex>
                        </>
                    );
                })}
            </Flex>
            <CenteredColumnFlexBox sx={{
                my: 4,
                mx: "auto",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "640px"
            }}>

                {/*{descriptions[activeStep] &&*/}
                {/*    <Alert sx={{width: "100%"}} severity={"info"}>{descriptions[activeStep]}</Alert>}*/}


                {(isFetchingWalletModuleAndRoles) ?

                    <Flex direction="column" align="center" gap="3" py="8">
                        <Text size="3" weight="medium" color="gray">
                            Loading Wallet Details
                        </Text>
                        <Spinner size="2"/>
                    </Flex>
                    :
                    <>
                        {getStepDescription(activeStep)}
                        {getStepContent(activeStep)}

                        <Flex width={"100%"} style={{margin: 32}} justify={"end"}>
                            <Button disabled={isNextDisabled || isNextLoading}
                                    onClick={handleNext}>
                                {getNextButtonLabel()}
                                {isNextLoading && <Spinner/>}
                            </Button>
                        </Flex>

                    </>
                }
            </CenteredColumnFlexBox>

            {/*<Box sx={{display: 'flex', flexDirection: "column", alignItems: 'flex-end'}}>*/}
            {/*    /!*{!isBackButtonHidden() && (*!/*/}
            {/*    /!*    <Button onClick={handleBack} sx={{mr: 1}}>*!/*/}
            {/*    /!*        {getBackButtonLabel()}*!/*/}
            {/*    /!*    </Button>*!/*/}
            {/*    /!*)}*!/*/}
            {/*    <Button disabled={isNextDisabled || isNextLoading}*/}
            {/*            onClick={handleNext}>*/}
            {/*        {getNextButtonLabel()}*/}
            {/*        {isNextLoading && <Spinner/>}*/}
            {/*    </Button>*/}


            {/*    /!*<Button onClick={() => fetchModules()}>GIMME MODULES</Button>*!/*/}
            {/*    /!*<Button onClick={() => deployRolesModule()}>DEPLOY ROLES MOD</Button>*!/*/}
            {/*    /!*<Button onClick={() => addRole()}>ADD ROLE</Button>*!/*/}
            {/*</Box>*/}
        </PageContentContainer>
    );
}
