import {
    Flex,
    Text,
    Button as RadixButton,
    Select as RadixSelect,
    Table,
    Card,
    IconButton as RadixIconButton,
    Spinner
} from "@radix-ui/themes";
import {MagnifyingGlassIcon, ReloadIcon} from "@radix-ui/react-icons";
import {PageContentContainer} from "../styled/styled";
import {
    Badge,
    Box,
    Button as MuiButton,
    CircularProgress,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Select as MuiSelect,
    Paper,
    TableContainer,
    TextField as MuiTextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Refresh} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import {SimulationTitleModal} from "../../modals/SimulationTitleModal";
import * as React from "react";
import {FlexBox} from "../simulation/create-simulation/styled";
import SearchIcon from "@mui/icons-material/Search";
import {formatDateValue, formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import {SimulationStatus} from "../simulation-history/SimulationStatus";
import {Link, useNavigate} from "react-router-dom";
import {ChainBadge} from "./ChainBadge";
import Chip from "@mui/material/Chip";
import useSWR from "swr";
import {getArtifactLibraryItems, getAllWalletBalances, getSmartWallets, getSupportedChains} from "../../api/hasura";
import WalletStatus from "./WalletStatus";
import {LDKeyWallets} from "../../components/layout/Sidebar";
import {useAppState} from "../../state/AppStateProvider";
import {formatPubKey} from "../../utils/commonUtils";


export type SupportedChain = {
    block_explorer_url: string;
    chain_id: string;
    chain_id_hex: string;
    chain_name_space: string;
    currency: string;
    is_active: boolean;
    is_layer2: boolean;
    is_testnet: boolean;
    minimum_comfirmation_block_number: number;
    name: string;
    rpc_url: string;
}

export type WalletBalance = {
    address: string;
    usd_value: number;
}

type WalletBalancesResponse = {
    message: string;
    total_usd_value: number;
    valid: boolean;
    wallet_count: number;
    balances: WalletBalance[];
}
export const WalletsOverview = () => {

    const {featureFlags} = useAppState();

    const isWalletsEnabled = featureFlags?.[LDKeyWallets];

    const navigate = useNavigate();


    const {
        data: supportedChains,
        isLoading: chainsLoading,
    } = useSWR("supported-chains", getSupportedChains)


    const [selectedChain, setSelectedChain] = React.useState<string>("ALL");


    const {
        data: userWallets,
        isLoading: isWalletsLoading,
        mutate: mutateWallets,
        isValidating: isWalletsValidating
    } = useSWR(`${selectedChain}-user-wallets`, () => getSmartWallets(selectedChain));

    const {
        data: walletBalances,
        isLoading: isBalancesLoading,
    } = useSWR<WalletBalancesResponse>('wallet-balances', getAllWalletBalances);


    const onChainChange = (e: any) => {
        setSelectedChain(e.target.value)
    };


    const balanceChange24 = 2;
    const onWalletSelect = (wallet: any) => {
        console.log("Wallet selected wallet", wallet);
        //navigate to wallets/:id

        if (wallet?.status === "READY" || wallet?.status === "IN_USE") {
            navigate(`/wallets/${wallet.id}`);

        } else {
            navigate(`/wallets/create/${wallet.id}`);
        }
    }

    const columns = [
        {field: 'name', headerName: 'Name', flex: 1},
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (item: any) => <WalletStatus wallet={item.row}/>
        },
        // {
        //     field: 'usdValue',
        //     headerName: 'Holdings Value',
        //     flex: 1,
        //     renderCell: (item: any) => <span>${formatMonetaryValue(item.row.usdValue)}</span>
        // },
        {
            field: 'chain', headerName: 'Chain', flex: 1, renderCell: (item: any) => {
                const chain = supportedChains?.find(chain => chain.chain_id === item.row.chain_id);

                return chain ? <ChainBadge chain={chain?.name}/> : null
            }
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 1,
            renderCell: (item: any) => <span>{formatPubKey(item.row.address)}</span>
        },
        {field: 'type', headerName: 'Type', flex: 1},

    ];


    if (!isWalletsEnabled) {
        return <PageContentContainer>
            <Flex justify="center" align="center" style={{height: "100%"}}>
                <Text size="6">Wallets feature is coming soon!</Text>
            </Flex>
        </PageContentContainer>
    }

    return (
        <PageContentContainer>
            <Flex direction="column" gap="6">
                <Flex direction="column" gap="4">
                    <Flex justify="between" align="center">
                        <h2>Wallets</h2>
                        <Link to="/wallets/create">
                            <RadixButton>Create Wallet</RadixButton>
                        </Link>
                        {/*<RadixIconButton*/}
                        {/*    onClick={mutateWallets}*/}
                        {/*    disabled={isWalletsLoading || isWalletsValidating}*/}
                        {/*>*/}
                        {/*    {isWalletsLoading || isWalletsValidating ?*/}
                        {/*        <ReloadIcon className="spinning" /> :*/}
                        {/*        <ReloadIcon />*/}
                        {/*    }*/}
                        {/*</RadixIconButton>*/}
                    </Flex>

                    <Flex direction="column">
                        <Text size="2" color="gray">Total Portfolio Value</Text>
                        <Text size="8" weight="bold">
                            {isBalancesLoading ? (
                                    <Spinner size="2"/>
                                ) :
                                <>

                                    ${formatMonetaryValue(walletBalances?.total_usd_value || 0)}
                                    {/*<Text*/}
                                    {/*    size="4"*/}
                                    {/*    style={{*/}
                                    {/*        paddingLeft: "8px",*/}
                                    {/*        color: Number(balanceChange24) < 0 ? "red" : "green",*/}
                                    {/*        fontWeight: 600*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    {Number(balanceChange24) >= 0 ? "+" : "-"}*/}
                                    {/*    {Number(balanceChange24) !== 0 ? `${formatMonetaryValue(balanceChange24)}%` : ''}*/}
                                    {/*</Text>*/}
                                </>
                            }
                        </Text>
                    </Flex>
                </Flex>

                <Flex justify="between" align="center">
                    <Flex gap="2">
                        <RadixSelect.Root value={selectedChain} onValueChange={setSelectedChain}>
                            <RadixSelect.Trigger/>
                            <RadixSelect.Content>
                                <RadixSelect.Item value="ALL">All Chains</RadixSelect.Item>
                                {supportedChains?.map(chain => (
                                    <RadixSelect.Item key={chain.chain_id} value={chain.chain_id}>
                                        {chain.name}
                                    </RadixSelect.Item>
                                ))}
                            </RadixSelect.Content>
                        </RadixSelect.Root>

                        <RadixSelect.Root defaultValue="SAFE">
                            <RadixSelect.Trigger/>
                            <RadixSelect.Content>
                                <RadixSelect.Item value="SAFE">Safe</RadixSelect.Item>
                                <RadixSelect.Item value="EULITH" disabled>Eulith</RadixSelect.Item>
                            </RadixSelect.Content>
                        </RadixSelect.Root>
                    </Flex>

                    <Flex gap="2">
                        {/*<RadixTextField.Root>*/}
                        {/*    <RadixTextField.Slot>*/}
                        {/*        <MagnifyingGlassIcon />*/}
                        {/*    </RadixTextField.Slot>*/}
                        {/*    <Input placeholder="Search..." disabled style={{ width: "140px" }} />*/}
                        {/*</RadixTextField.Root>*/}
                    </Flex>
                </Flex>

                {isWalletsLoading ? (
                    <Flex direction="column" align="center" gap="3" py="8">
                        <Text size="3" weight="medium" color="gray">
                            Loading Wallets
                        </Text>
                        <Spinner size="2"/>
                    </Flex>
                ) : (
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Chain</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Address</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Balance</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {userWallets?.map((wallet: any) => {
                                const walletBalance = walletBalances?.balances?.find(
                                    (balance) => balance?.address === wallet?.address
                                );

                                return (
                                    <Table.Row
                                        key={wallet.id}
                                        onClick={() => onWalletSelect(wallet)}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <Table.Cell>{wallet.name}</Table.Cell>
                                        <Table.Cell><WalletStatus wallet={wallet}/></Table.Cell>
                                        <Table.Cell>
                                            {supportedChains?.find(chain => chain.chain_id === wallet.chain_id)?.name &&
                                                <ChainBadge
                                                    chain={supportedChains.find(chain => chain.chain_id === wallet.chain_id)!.name}/>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>{formatPubKey(wallet.address)}</Table.Cell>
                                        <Table.Cell>
                                            {isBalancesLoading ? (
                                                <Spinner size="1"/>
                                            ) : (
                                                (wallet.status === "READY" || wallet.status === "IN_USE") ?
                                                    `$${formatMonetaryValue(walletBalance?.usd_value || 0)}` :
                                                    "-"
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>{wallet.type}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table.Root>
                )}
            </Flex>
        </PageContentContainer>
    );
}
