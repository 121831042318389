import { Flex, IconButton, Text } from '@radix-ui/themes';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';

interface TokenLinkProps {
  token: {
    id: string;
    chain: string;
  };
  blockExplorerUrl: string;
}

const TokenLink = ({ token, blockExplorerUrl }: TokenLinkProps) => {
  // Check if token.id is a valid address (0x followed by 40 hex characters)
  const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(token.id);
  
  if (!isValidAddress) {
    return null;
  }

  const getTokenUrl = () => {
    return `${blockExplorerUrl}/token/${token.id}`;
  };

  return (
    <Flex gap="2" align="center">
      <Text>{token.id}</Text>
      <IconButton variant="ghost" onClick={(e) => {
        e.stopPropagation();
        window.open(getTokenUrl(), '_blank');
      }}>
        <OpenInNewWindowIcon />
      </IconButton>
    </Flex>
  );
};

export default TokenLink;