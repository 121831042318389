import React from 'react';
import {Button} from "@radix-ui/themes";
import {track} from '@vercel/analytics';
import {Alert} from "@mui/material";
import {IS_PRODUCTION} from "../index";


const FakeDoorButton = ({triggerTitle, featureKey, variant}: { triggerTitle: string, featureKey: string, variant: any }) => {

    const [isTriggered, setIsTriggered] = React.useState(false);
    const handleButtonClick = () => {
        // Send an analytics event to Vercel Actions
        // if (IS_PRODUCTION) {
            track(`FakeDoor-${featureKey}`);
        // }
        setIsTriggered(true);
    };


    return <> {isTriggered && <Alert severity="info">This feature is still under development! Stay tuned for updates!</Alert>}
        <div><Button disabled={isTriggered} variant={variant} onClick={handleButtonClick}>{triggerTitle}</Button></div>
    </>
};

export default FakeDoorButton;
