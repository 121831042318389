export const formatTokenAmount = (amount: number, decimals: number = 4) => {
    if (!amount) return '0';
    
    // For extremely small numbers (less than 1e-8), use scientific notation
    if (amount < 1e-8 && amount > 0) {
        return amount.toExponential(2); // Keep it simple with 2 decimals
    }
    
    // For small but not tiny numbers (between 1e-8 and 0.01)
    if (amount < 0.01 && amount >= 1e-8) {
        return amount.toFixed(8);
    }
    
    // For medium small numbers (between 0.01 and 1)
    if (amount < 1 && amount >= 0.01) {
        return amount.toFixed(6);
    }
    
    // For numbers >= 1
    return amount.toFixed(decimals);
};