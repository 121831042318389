import { Text } from "@radix-ui/themes";

interface AgentStatusProps {
    status: string;
    size?: "1" | "2" | "3";
}

export const AgentStatus = ({ status, size = "2" }: AgentStatusProps) => {
    console.log("AgentStatus status", status);
    const getStatusStyles = (status: string) => {
        if (status.startsWith('ERROR')) {
            return {
                backgroundColor: '#991b1b',
                color: '#fee2e2'
            };
        } else if (status === 'PENDING') {
            return {
                backgroundColor: '#fff3dc',
                color: '#996b00'
            };
        } else if (status === 'IN_USE') {
            return {
                backgroundColor: '#dbeafe',
                color: '#1e40af'
            };
        } else if (status === "DEPLOYING") {
            return {
                backgroundColor: 'var(--accent-a4)',
                color: '#334155'
            };
        } else if (status === "RUNNING") {
            return {
                backgroundColor: 'var(--accent-a11)',
                color: 'white'
            };
        } else if (status === "TERMINATED") {
            return {
                backgroundColor: '#fee2e2',
                color: '#991b1b'
            };
        } else if (status === "NORMAL_PAUSE") {
            return {
                backgroundColor: '#dbeaf1',
                color: '#1e40af'
            };
        }
        return {
            backgroundColor: '#fff3dc',
            color: '#996b00'
        };
    };

    const statusStyles = getStatusStyles(status);

    const displayStatus = status.toLowerCase().replace(/_/g, ' ');


    return (
        <Text
            size={size}
            style={{
                padding: '4px 8px',
                borderRadius: '4px',
                textTransform: "capitalize",
                ...statusStyles
            }}
        >
            {displayStatus}
        </Text>
    );
};

interface AgentStatusProps {
    status: string;
    size?: "1" | "2" | "3";
}
