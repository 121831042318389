import Box from "@mui/material/Box";
import * as React from "react";
import {PageContentContainer} from "../styled/styled";
import {useState, useEffect} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {openAlmanakDocs} from "../../components/layout/Sidebar";
import {useAppState} from "../../state/AppStateProvider";
import {useTheme} from "@mui/material";
import {
    PlayCircleOutline,
    Computer,
    Settings,
    Nature,
    QuestionMark,
    People,
    Assessment,
    OpenInBrowserOutlined
} from '@mui/icons-material';
import {Button, Flex, Text} from "@radix-ui/themes";
import {OpenInNewWindowIcon, QuestionMarkIcon} from "@radix-ui/react-icons";
import { OnboardingModal } from "../../components/almanak-modal/OnboardingModal";
import useSWR from "swr";
import { getUser } from "../../api/hasura";
import AlmanakModal from "../../components/almanak-modal/AlmanakModal";

export const Dashboard = () => {

    const {user, setSnackBar} = useAppState();
    const [isDocsTokenGenerating, setIsDocsTokenGenerating] = useState(false);

    const [showOnboarding, setShowOnboarding] = useState(false);

    const {
        data: userDetails,
        mutate: mutateUserDetails
    } = useSWR(user?.id ? `user-details` : null, () => getUser(user?.id!));

    useEffect(() => {
        if (userDetails?.is_first_time) {
            setShowOnboarding(true);
        }
    }, [userDetails]);

    const openDocs = async (subUrl: string) => {
        setIsDocsTokenGenerating(true);
        try {
            await openAlmanakDocs(subUrl);
        } catch (err: any) {
            setSnackBar({open: true, message: "Could not open docs, please contact support", severity: "error"});
        } finally {
            setIsDocsTokenGenerating(false);
        }
    }

    const panels = [
        { title: 'Introduction', icon: <QuestionMarkIcon />, url: "/docs"},
        { title: 'Getting Started', icon: <PlayCircleOutline />, url: "/docs/getting-started"},
        { title: 'Configuration', icon: <Settings />, url: "docs/configuration"},
        { title: 'Simulator', icon: <Computer />, url: "docs/simulator"},
        { title: 'Agent Based Modeling', icon: <People />, url: "docs/abm"},
        { title: 'Results', icon: <Assessment />, url: "guides/results"},
    ];

    const PanelList = ({ isDocsTokenGenerating, openDocs }: any) => {
        const theme = useTheme();

        return (
            <Flex direction={"row"} gap={"4"} wrap={"wrap"}>
                {panels.map((panel, index) => (
                    <Button key={panel.title} variant={"soft"} loading={isDocsTokenGenerating} onClick={() => openDocs(panel.url)}>
                        <span className={"text-bold"} style={{marginLeft: "8px", fontSize: "16px"}}>{panel.title}</span>
                        <OpenInNewWindowIcon />
                    </Button>))
                }

            </Flex>
        );
    };

    return <PageContentContainer>
        {user && userDetails && (
                <OnboardingModal 
                    isOpen={showOnboarding}
                    onClose={() => setShowOnboarding(false)}
                    userId={user.id}
                />
            )}

        <Box sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap"
        }}>
            <Box sx={{my: 2, display: "flex", gap: 1, flexDirection: "column", maxWidth: "660px"}}>
                <Text style={{
                    fontSize: "32px",
                    fontWeight: "700",
                    marginBottom: "32px"
                }}>Welcome to Almanak</Text>
                <Text className={"text-bold"}>
                    The end-to-end platform for managing autonomous financial agents in crypto
                </Text>
                <Text>
                    Almanak is a platform for creating, testing, optimizing, deploying and monitoring financial agents in crypto. Trade, generate yield and manage risk using your private army of financially intelligent agents.
                </Text>

                <Text style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    marginTop: "32px"
                }}>New to Multiverse?</Text>

                <Text style={{marginBottom: "16px"}}>
                    Start with the Getting Started guide to learn how to create your first simulation.
                </Text>

                <PanelList isDocsTokenGenerating={isDocsTokenGenerating} openDocs={openDocs}/>

            </Box>
        </Box>
    </PageContentContainer>

}
