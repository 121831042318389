import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox, FlexBox, FlexCol} from "../views/simulation/create-simulation/styled";
import {useNavigate} from "react-router-dom";
import {LDKeyStrategyLibraryMaintenance, useAppState} from "../state/AppStateProvider";
import useSWR from "swr";
import {getArtifactLibraryItems, getPublicArtifactLibraryItems} from "../api/hasura";
import Fuse from "fuse.js";
import {useEffect, useState} from "react";
import {ArtifactItem} from "../views/strategy/ArtifactItem";
import Box from "@mui/material/Box";
import {LDKeyStrategyLibraryWidget, openAlmanakDocs} from "../components/layout/Sidebar";
import {
    Button,
    Callout,
    Dialog,
    Flex,
    IconButton,
    Inset,
    Spinner,
    Table,
    Tabs,
    Text,
    TextField
} from "@radix-ui/themes";
import {ExclamationTriangleIcon, MagnifyingGlassIcon, OpenInNewWindowIcon} from "@radix-ui/react-icons";
import {Card} from "@radix-ui/themes";
import AlmanakModal from "../components/almanak-modal/AlmanakModal";
import FakeDoorButton from "../components/fake-door-button";

// Fuse.js configuration
const options = {
    includeScore: true,
    threshold: 0.2,
    // Fields to search in
    keys: ['author_user.email', 'author_user.name', 'latest_version_artifact.name', 'metadata.input_features', 'metadata.output_structure', 'metadata?.tags', 'name', 'description'],
};

export type ArtifactFile = {
    id: string;
    uri: string
    authenticated_url: string;
    date_created: string;
    description: string;
}

export type LibraryArtifactVersion = {
    artifact_files: ArtifactFile[],
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null }
    chain_id?: string;
}
export type LibraryArtifact = {
    latest_version_artifact: LibraryArtifactVersion,
    artifact_versions: LibraryArtifactVersion[],
    is_star_by_user: boolean;
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    latest_public_version: string | null,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null },
    stars_count: number,
    artifact_stars: ArtifactStar[]
}

export type ArtifactStar = {
    artifact: LibraryArtifact;
    artifact_id: string;
    user_id: string;
    created_at: string;
    id: string;
}

export const StrategyLibraryWidget = () => {

    // const formController = useForm<StrategyLibraryForm>({
    //         defaultValues: {},
    //         reValidateMode: "onChange"
    //     }
    // );
    const navigate = useNavigate();

    const {featureFlags, user} = useAppState();
    const isStrategyLibraryMaintenanceMode = featureFlags?.[LDKeyStrategyLibraryMaintenance];
    const isStrategyLibraryEnabled = featureFlags?.[LDKeyStrategyLibraryWidget];

    const [searchValue, setSearchValue] = useState("");
    const [selectedTab, setSelectedTab] = useState("private");
    const [displayedArtifacts, setDisplayedArtifacts] = React.useState<LibraryArtifact[]>([]);
    const [searcher, setSearcher] = useState<Fuse<LibraryArtifact>>();


    const {
        data: artifactList,
        isLoading,
    } = useSWR("strategy-templates", getArtifactLibraryItems)


    console.log("user", user);
    const userId = user?.id;

    const {
        data: rawPublicArtifacts,
        isLoading: isPublicArtifactsLoading
    } = useSWR("public-artifacts", userId ? () => getPublicArtifactLibraryItems(userId) : null);

    const publicArtifacts = rawPublicArtifacts?.map((artifact: any) => ({
        ...artifact,
        is_public: true,
    })) || [];


    console.log("publicArtifacts", publicArtifacts);
    const openDetails = (strategy: LibraryArtifact) => {
        navigate(`/strategy-library/${strategy.id}`)
    }

    console.log("artifactList", artifactList);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onTabChange = (tab: string) => {
        setSelectedTab(tab);
        setSearchValue(""); // Reset search when changing tabs
    };

    // Initialize/update the searcher when artifactList changes
    useEffect(() => {
        if (artifactList) {
            setSearcher(new Fuse(artifactList, options));
        }
    }, [artifactList]);

    // Handle filtering and searching
    useEffect(() => {
        if (!artifactList) return;

        let baseList = artifactList;
        switch (selectedTab) {
            case 'private':
                baseList = artifactList.filter((artifact: LibraryArtifact) => artifact.author === user?.id);
                break;
            case 'starred':
                baseList = artifactList.filter((artifact: LibraryArtifact) =>
                    artifact.artifact_stars?.some(star => star.user_id === user?.id)
                );
                break;
            default: // 'all'
                baseList = publicArtifacts;
        }

        if (searchValue && searcher) {
            const result = searcher.search(searchValue);
            setDisplayedArtifacts(result
                .map(r => r.item)
                .filter(item => baseList.some((b: LibraryArtifact) => b.id === item.id))
            );
        } else {
            setDisplayedArtifacts(baseList);
        }
    }, [publicArtifacts, artifactList, selectedTab, searchValue, searcher, user?.id]);

    if (!isStrategyLibraryEnabled) {
        return <PageContentContainer>
            <Box sx={{m: 4, display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <h2>Strategy library feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }


    const createStrategyModalWithTrigger = <Dialog.Root>
        <Dialog.Trigger>
            <Button>Create Strategy</Button>
        </Dialog.Trigger>
        <Dialog.Content>
            <Dialog.Title>Create a new Strategy</Dialog.Title>


            <Flex direction={"column"} gap={"1"} my="2" mt="4">
                <Text weight="bold" size="4">Code using the SDK</Text>
                <Text size={"3"}>Create new Agent Strategies using the Almanak SDK</Text>
                <div><Button variant={"outline"}
                             onClick={() => openAlmanakDocs(`/docs/getting-started/`)}>
                    Get Started<OpenInNewWindowIcon/>
                </Button></div>

            </Flex>

            <Flex direction={"column"} gap={"1"} my="2" mt="6">
                <Text weight="bold" size="4">Using Natural Language</Text>
                <Text size={"3"}>Create new Agent Strategies using Artificial Intelligence</Text>

                <FakeDoorButton variant="outline" triggerTitle="Create with AI" featureKey="create-strategy-with-ai"/>


            </Flex>
            <Flex gap="3" justify="end">
                <Dialog.Close>
                    <Button variant="soft" color="gray">
                    Close
                    </Button>
                </Dialog.Close>
            </Flex>
        </Dialog.Content>
    </Dialog.Root>;

    return <PageContentContainer>


        <CenteredRowFlexBox sx={{justifyContent: "space-between"}}>
            <h2>Strategies</h2>

            {createStrategyModalWithTrigger}
        </CenteredRowFlexBox>

        <Box>

            {isStrategyLibraryMaintenanceMode &&
                <Callout.Root color="red">
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        Strategy Library is currently in maintenance mode. Unexpected errors may occur.
                    </Callout.Text>
                </Callout.Root>
            }

            <Box sx={{my: 2, maxWidth: "420px"}}>
                <TextField.Root placeholder="Find strategies..." value={searchValue}
                                onChange={onSearch}>
                    <TextField.Slot>
                        <MagnifyingGlassIcon/>
                    </TextField.Slot>
                </TextField.Root>
            </Box>


            <Tabs.Root style={{marginBottom: "8px"}} defaultValue="private" value={selectedTab}
                       onValueChange={(value: string) => onTabChange(value)}>
                <Tabs.List>
                    <Tabs.Trigger value="private">My strategies</Tabs.Trigger>
                    <Tabs.Trigger value="all">Public Strategies</Tabs.Trigger>
                    <Tabs.Trigger value="starred">Starred</Tabs.Trigger>
                </Tabs.List>
            </Tabs.Root>

            {/*<TextField*/}
            {/*    InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*            <InputAdornment position="start">*/}
            {/*                <SearchIcon/>*/}
            {/*            </InputAdornment>*/}
            {/*        )*/}
            {/*    }}*/}
            {/*    onChange={onSearch}*/}
            {/*    size={"small"} variant={"outlined"} placeholder="Search by name, description, tags etc..."*/}
            {/*    sx={{width: "100%", maxWidth: "400px"}}/>*/}

            {displayedArtifacts?.length === 0 &&
                <Callout.Root color="blue">
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        No strategies found. Please try a different search.
                    </Callout.Text>
                </Callout.Root>
            }

        </Box>
        {isLoading && <FlexBox sx={{justifyContent: "center", alignItems: "center", gap: 2, m: 4}}>
            Loading Strategies <Spinner/>
        </FlexBox>
        }
        <Card style={{padding: 0}}>
            {displayedArtifacts?.map((artifact: LibraryArtifact) =>
                <ArtifactItem key={artifact?.id} artifact={artifact}/>
            )}
        </Card>
    </PageContentContainer>

}
