import React from 'react';
import {ethers} from 'ethers';
import {Button, IconButton, TextField} from '@radix-ui/themes';
import {Alert, Box, Modal} from "@mui/material";
import {AlmanakModalContent} from "../modals/ScenarioCustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {useAppState} from "../state/AppStateProvider";
import {useWallets} from "@privy-io/react-auth";
import useSWR from "swr";
import {getTopUpPaymentSmartContract} from "../api/hasura";


const TopUpComponent = ({onClose, chainId, eoaWalletAddress}: {onClose: any, chainId: string, eoaWalletAddress: string}) => {


    console.log("TopUpComponent eoaWalletAddress", eoaWalletAddress);
    const {
        data: topUpPaymentSmartContract
    } = useSWR("topUpPaymentSmartContract", getTopUpPaymentSmartContract)


    console.log("TopUpComponent topUp SmartContracts", topUpPaymentSmartContract);
    const {provider, setSnackBar} = useAppState();

    const {wallets} = useWallets();

    console.log("TopUp wallets", wallets);
    const connectedWallet = wallets?.[0];



    console.log("TopUpComponent wallets", wallets);
    const topUpContract = topUpPaymentSmartContract?.find((contract: any) => contract.chain_id === chainId)
    const topUpABI = topUpContract?.abi;
    const topUpAddress = topUpContract?.address;

    const [topUpAmount, setTopUpAmount] = React.useState("0.005");


    const handleTopUp = async () => {

        setIsFundingEOA(true);
        onClose()

        try {

            await connectedWallet?.switchChain(Number(chainId));

            // const walletProvider = await connectedWallet.getEthereumProvider();

            console.log("TopUpComponent chainId", chainId);
            console.log("TopUpComponent topUpContract for chain", topUpContract);

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            const recipientAddress = eoaWalletAddress;

            // Request access to the wallet (if not already connected)
            await provider.send("eth_requestAccounts", []);

            console.log("TopUpComponent recipientAddress", recipientAddress);
            console.log("TopUpComponent provider", provider);
            console.log("TopUpComponent topUpAddress", topUpAddress);

            const topUp = new ethers.Contract(topUpAddress, topUpABI, signer);
            console.log("TopUpComponent topUp", topUp);

            const parsedTopUpAmount = ethers.utils.parseEther(topUpAmount?.toString());

            console.log(
                `TopUpComponent Topping up ${ethers.utils.formatEther(parsedTopUpAmount)} ETH to ${recipientAddress}`
            );


            // Call the topUp function with the recipient address and send the specified amount
            const tx = await topUp.topUp(recipientAddress, {value: parsedTopUpAmount});


            console.log("TopUpComponent tx", tx);

            const receipt = await tx.wait();

            console.log("TopUpComponent tx receipt", receipt);

            setSnackBar({open: true, message: `Transaction Successfull`, severity: "success"});

        } catch (error: any) {
            setSnackBar({open: true, message: `Failed: ${error?.message?.substr(0, 200)}`, severity: "error"});

            console.error("Error interacting with the contract:", error);
        } finally {
            setIsFundingEOA(false);
        }
    };


    const [isFundingEOA, setIsFundingEOA] = React.useState(false);

    return (

        (
            <Modal
                sx={{display: "flex"}}
                open={true}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AlmanakModalContent>
                    <Box sx={{
                        p: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "bold"
                    }}>
                        Top Up EOA Wallet
                        <IconButton variant="outline"
                                    onClick={() => onClose()}><CloseIcon/></IconButton>
                    </Box>

                    <Alert severity={"info"} sx={{m: 1}}>
                        You will be funding Almanak EOA wallet which will be performing transactions for
                        you.
                    </Alert>


                    <Box sx={{m: 2, maxWidth: "420px"}}>
                        <span>ETH Amount to Top Up</span>
                        <TextField.Root placeholder="ETH amount to top up" value={topUpAmount}
                                        onChange={(e: any) => setTopUpAmount(e.target.value)}>
                        </TextField.Root>

                    </Box>


                    <Box sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}>
                        <Button style={{borderRadius: "4px", padding: "4px 12px"}} variant="soft"
                                onClick={() => onClose()}>Cancel</Button>
                        <Button loading={isFundingEOA} style={{borderRadius: "4px", padding: "4px 12px"}} color="green"
                                onClick={handleTopUp}>Proceed</Button>
                    </Box>

                </AlmanakModalContent>
            </Modal>)


        // <div>
        //   <Button onClick={handleTopUp} variant="solid">Top Up</Button>
        // </div>
    );
};

export default TopUpComponent;
