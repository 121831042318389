import { Cross2Icon } from '@radix-ui/react-icons';
import { Button, Dialog, Flex, Text, Box } from '@radix-ui/themes';
import { useAppState } from '../../state/AppStateProvider';
import { updateUserFirstTimeStatus } from '../../api/hasura';

interface OnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

export const OnboardingModal = ({ isOpen, onClose, userId }: OnboardingModalProps) => {
  const { setSnackBar } = useAppState();

  const handleClose = async () => {
    // Close modal immediately for better UX
    onClose();
    
    try {
      // Update user status in background
      await updateUserFirstTimeStatus(userId, false);
    } catch (error: any) {
      setSnackBar({
        open: true,
        message: "Failed to update user status",
        severity: "error"
      });
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleClose}>
      <Dialog.Content size="4" style={{ maxWidth: 800, padding: '32px' }}>
        <Flex direction="column" gap="5">
          <Flex justify="between" align="start">
            <Dialog.Title>
              <Text size="8" weight="bold">Welcome to Almanak!</Text>
            </Dialog.Title>
            <Dialog.Close style={{ marginTop: '-8px' }}> 
              <Button variant="ghost" color="gray" size="3">
                <Cross2Icon width={20} height={20} />
              </Button>
            </Dialog.Close>
          </Flex>
          
          <Dialog.Description>
            <Text size="4" style={{ lineHeight: '1.6' }}>
              Before getting started, watch the friendly welcome video from the founders. 
              Should take you less than a minute! Or skip it and jump straight to business.
            </Text>
          </Dialog.Description>

          <Box style={{ 
            position: 'relative',
            paddingBottom: '56.25%',
            height: 0,
            overflow: 'hidden',
            borderRadius: 'var(--radius-4)',
            backgroundColor: 'var(--gray-12)'
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              src="https://www.youtube.com/embed/Pw605oUcM9k"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};